export function camelCaseToTitleCase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
    .replace(/^./, str => {
      return str.toUpperCase();
    });
}

export function convertCamelPropNameToNormalString(
  inputString: string
): string {
  return inputString
    .replace(/([A-Z])/g, " $1")
    .replace(/^\s/, "")
    .replace(/\b\w/g, firstLetter => firstLetter.toUpperCase());
}
