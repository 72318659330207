import { camelCaseToTitleCase } from "../../../helpers/camelCaseToTitleCase";
export const tableColumnListAgencies = {
  agencyName: {
    formatDataKey: "Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  agencyCode: {
    formatDataKey: "Code",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    colWidth: 400
  },
  status: {
    formatDataKey: "Status",
    formatDataValue: (arg: string) => camelCaseToTitleCase(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    colWidth: 150
  }
};
